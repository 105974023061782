function popup() {
    const openPopupBtn = document.querySelectorAll('.openPopup');

    if(openPopupBtn) {
        const popup = document.querySelector('.popup'),
            popupClose = document.querySelector('.popup__close');

        openPopupBtn.forEach(el=> {
            el.addEventListener('click', ()=> {
                popup.classList.add('show');
            });

            if(document.querySelector('.header__nav-wrapper').classList.contains('show')) {
                console.log('хуй')
            }
        });

        popupClose.addEventListener('click', ()=> {
            popup.classList.remove('show');
        });

        document.addEventListener('click', (e)=> {
            if(e.target == popup) {
                popup.classList.remove('show');
            }
        });
    }
}
    
module.exports = popup;