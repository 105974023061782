function some() {
    //Removing extra characters in the short description
    let blogShortDescr = document.querySelectorAll('.last-posts__txt');

    if (blogShortDescr) {
        blogShortDescr.forEach(el => {
            let text = el.innerHTML;
            let strLenght = text.length;
            setTimeout(()=> {
                let breakTxt = el.querySelector('br');
                breakTxt.remove();
            }, 100);
            

            function checkAndShorten() {
                text = text.substring(0, 73);
                el.innerText = text + '...';
            }

            if (strLenght > 25) {
                checkAndShorten();
                return;
            } else {
                setTimeout(checkAndShorten, 100);
            }
        });
    } 
}
    
module.exports = some;