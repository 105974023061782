function forms() {
    const forms = document.querySelectorAll('.form');

    if(forms) {
        const completePopup = document.querySelector('.complete'),
            completeClose = document.querySelector('.complete__close');

        forms.forEach(item => {
            postData(item);
        });

        function postData(form) {
            form.addEventListener('submit', (e)=> {
                e.preventDefault();
                
                const request = new XMLHttpRequest();
                request.open('POST', '/');
    
                const formData = new FormData(form);
    
                request.send(formData);
    
                request.addEventListener('load', ()=> {
                    if(request.status === 200) {
                        if(document.querySelector('.popup').classList.contains('show')) {
                            document.querySelector('.popup').classList.remove('show');
                        }
                        setTimeout(()=> {
                            form.reset();
                            completePopup.classList.add('active');
                            setTimeout(()=> {
                                completePopup.classList.remove('active');
                            }, 3000);
                        }, 300);
                    } else {
                        alert('Что-то пошло не так, попробуйте снова или проверьте подключение к интернету');
                    }
                });
            });  
        }
    }
}
    
module.exports = forms;