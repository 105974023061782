function nav() {
    const burger = document.querySelector('.burger'),
          navWrapper = document.querySelector('.header__nav-wrapper'),
          navLinks = document.querySelectorAll('.header__nav-link');

    burger.addEventListener('click', ()=> {
        burger.classList.toggle('close');
        navWrapper.classList.toggle('show');
    });

    navLinks.forEach(el=> {
        el.addEventListener('click', ()=> {
            burger.classList.remove('close');
            navWrapper.classList.remove('show');
        });
    });

}
    
module.exports = nav;