function slider() {
    const reviews = document.querySelector('.reviews');

    if(reviews) {
        setTimeout(()=> {
            let sliderReviews = new Swiper(".reviews", {
                speed: 800,
                pagination: {
                    el: ".reviews__pagination",
                    type: 'bullets',
                    clickable: true,
                },
                breakpoints: {
                    1: {
                        slidesPerView: 'auto',
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1261: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                }
            });
        }, 1000);

        function onYouTubePlayerAPIReady() {
            const reviewsVideoWrappers = document.querySelectorAll('.reviews__video-wrapper');
          
            reviewsVideoWrappers.forEach(el => {
              el.addEventListener('click', (e) => {
                const videos = e.target.querySelector('iframe');
                videos.style.pointerEvents = 'visible';
          
                player = new YT.Player(videos, {
                  events: {
                    onReady: function (event) {
                      setTimeout(function () {
                        player.playVideo();
                      }, 300);
                    },
                    onStateChange: function (event) {
                        if (event.data === YT.PlayerState.PAUSED) {
                          videos.style.pointerEvents = 'none'; // Скрыть клики на видео при паузе
                        }
                      },
                  }
                });
              });
            });
          }
          
          // Инициализация объекта плеера
          onYouTubePlayerAPIReady();
    }
}
module.exports = slider;