function hovered() {
    const services = document.querySelector('#services');

    if(services) {
        //Services section
        const servicesImgs = document.querySelectorAll('.services__img'),
        servicesImg = document.querySelector('.services__img--first'),
        servicesLinks = document.querySelectorAll('.services__item');

        servicesImg.classList.add('show');

        function showServicesImgs(i = 0) {
            servicesImgs[i].classList.add('show');
            servicesImg.classList.remove('show');
        }

        servicesLinks.forEach((el, i)=> {
            el.addEventListener('mouseleave', ()=> {
                servicesImgs.forEach(el=> {
                    el.classList.remove('show');
                });
                servicesImg.classList.add('show');
            });
        });

        servicesLinks.forEach((el, i)=> {
            el.addEventListener('mouseenter', ()=> {
                servicesImgs.forEach(el=> {
                    el.classList.remove('show');
                });
                showServicesImgs(i);
            });
        });
    }


    /* Steps section */
    const steps = document.querySelector('.steps');

    if(steps) {
        const stepsCountWrapper = document.querySelectorAll('.steps__header-col'),
            stepsCount = document.querySelectorAll('.steps__count'),
            stepsDescr = document.querySelectorAll('.steps__descr');

        stepsCount[0].classList.add('active');
        stepsDescr[0].style.display = 'flex';

        function changeStep (i = 0) {
            stepsCount[i].classList.add('active');
            stepsDescr[i].style.display = 'flex';
        }

        stepsCountWrapper.forEach((el, i)=> {
            el.addEventListener('mouseenter', ()=> {
                stepsCount.forEach(el=>{el.classList.remove('active');});
                stepsDescr.forEach(el=> {el.style.display = 'none';});
                changeStep(i);
            });
        });
    }
}
    
module.exports = hovered;