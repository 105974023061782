function anim() {
    const animBottomToTop = document.querySelectorAll('[data-anim="bottomToTop"]'),
        animFadeIn = document.querySelectorAll('[data-anim="fadeIn"]');

        //Add animationScrollToTop
        const observerAnimBottomToTop = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                animBottomToTop.forEach((el, i)=> {
                    i++;
                    el.setAttribute("id", `animBottomToTop${i}`);
                });

                const activeId = entry.target.id;

                if (entry.isIntersecting) {
                    document.getElementById(activeId).classList.add('anim');
                }
            });
        }, {threshold: [.4]});
        animBottomToTop.forEach(el => observerAnimBottomToTop.observe(el));

        //Add anim Fade In
        const observerFadeIn = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                animFadeIn.forEach((el, i)=> {
                    i++;
                    el.setAttribute("id", `animFadeIn${i}`);
                });

                const activeId = entry.target.id;

                if (entry.isIntersecting) {
                    document.getElementById(activeId).classList.add('anim');
                }
            });
        }, {threshold: [.4]});
        animFadeIn.forEach(el => observerFadeIn.observe(el));

        if(document.querySelector('.section--about ')) {
            const about = document.querySelector('.about');
            const observerAbout = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        about.classList.add('anim');
                    }
                });
            }, {threshold: [.4]});
            observerAbout.observe(document.querySelector('.section--about '));
        }

}
    
module.exports = anim;