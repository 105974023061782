document.addEventListener("DOMContentLoaded", ()=> {
    let nav = require('./modules/nav'),
        hovered = require('./modules/hovered'),
        anim = require('./modules/anim'),
        scroll = require('./modules/scroll'),
        some = require('./modules/some'),
        slider = require('./modules/slider'),
        forms = require('./modules/forms'),
        popup = require('./modules/popup');
    
        nav();
        hovered();
        anim();
        scroll();
        some();
        slider();
        forms();
        popup();
});