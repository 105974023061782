function scroll() {
    if(document.querySelector('.first-screen')) {
        const smoothScroll = document.querySelectorAll('.smoothScroll'),
            speed = .1;

            smoothScroll.forEach(el=> {
                el.addEventListener('click', function(e) { 
                    e.preventDefault();
                    let w = window.pageYOffset,
                        hash = this.href.replace(/[^#]*(.*)/, '$1'),
                        t = document.querySelector(hash).getBoundingClientRect().top,
                        start = null;
                    requestAnimationFrame(step);
                    function step(time) {
                        if (start === null) start = time;
                        let progress = time - start,
                            r = (t < 0 ? Math.max(w - progress/speed, w + t) : Math.min(w + progress/speed, w + t));
                        window.scrollTo(0,r);
                        if (r != w + t) {
                            requestAnimationFrame(step);
                        } else {
                            location.hash = hash;
                        }
                    }
                }, false);
            });
    }
}
    
module.exports = scroll;